/**
 * ----------------------------------------
 * Variables
 * ----------------------------------------
 */

// pink
$pink: #ff3b86;
$light-pink: #fcb8d2;

// purple
$purple: #9d7fe3;
$light-purple: #c3b6e2;

// gray
$gray: #cacaca;
$dark-gray: #333a41;
$light-gray: #1e232b;

// blue
$light-blue: #d2e2fa;

// gradation
$gradient-pink-purple: linear-gradient(125.07deg, $pink 0%, $purple 66.18%);


$background: linear-gradient(to right, #212121 0%, #484848 100%);
$backgroundCard: #707070;
$backgroundBase: #212121;
$backgroundSplash: #000000;
$buttons: #404040;
$featureText: #FFBF00;
$primaryText: #FFFFFF;
$secondaryText: #A3A3A3;
$red: #BF0000;

/**
 * ----------------------------------------
 * Exports
 * ----------------------------------------
 */

:export {
  pink: $pink;
  purple: $purple;
  light-purple: $light-purple;
  gray: $gray;
  dark-gray: $dark-gray;
  light-gray: $light-gray;
  light-blue: $light-blue;
  backgroundCard: $backgroundCard;
  background: $background;
  backgroundBase: $backgroundBase;
  backgroundSplash: $backgroundSplash;
  buttons: $buttons;
  featureText: $featureText;
  primaryText: $primaryText;
  secondaryText: $secondaryText;
  red: $red;
}